<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.ancient')" />
    <img
      src="http://cdn.topartsintermational.com/ancient-img-s.jpg"
      width="100%"
    />
    <div class="part part1">
      <h1>{{ setting.part1.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part1.des }}
      </div>
      <div class="cont" style="margin-top: 24px">
        <div class="cont-left">
          <img
            src="http://cdn.topartsintermational.com/ancient-img1.jpg"
            width="100%"
          />
        </div>
        <div class="cont-right">
          <p>{{ setting.part1.cont1 }}</p>
        </div>
      </div>
      <div class="cont cont1" style="margin-top: 16px">
        <div class="cont1-left">
          <p>{{ setting.part1.cont2 }}</p>
        </div>
        <div class="cont1-right">
          <img
            src="http://cdn.topartsintermational.com/ancient-img2.jpg"
            width="100%"
          />
        </div>
      </div>
    </div>
    <div class="part part2">
      <h1>{{ setting.part2.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part2.des }}
      </div>
      <div class="row" style="margin-top: 24px">
        <div class="col">
          <img
            src="http://cdn.topartsintermational.com/ancient-img4.jpg"
            width="100%"
          />
        </div>
        <div class="col">
          <img
            src="http://cdn.topartsintermational.com/ancient-img5.jpg"
            width="100%"
          />
        </div>
      </div>
      <div class="row">
        <div class="left">
          <p>{{ setting.part2.cont }}</p>
        </div>
        <div class="right">
          <img
            src="http://cdn.topartsintermational.com/ancient-img6.jpg"
            width="100%"
            @click="visible = true"
          />
        </div>
      </div>
    </div>
    <div class="part part3">
      <h1>{{ setting.part3.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part3.des }}
      </div>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="item in 21" :key="item">
          <img
            :src="
              'http://cdn.topartsintermational.com/ancient-carousel' +
              item +
              '.jpg'
            "
            width="400px"
            height="300px"
          />
        </swiper-slide>
        <div class="swiper-button-prev swiper-btn" slot="button-prev"></div>
        <div class="swiper-button-next swiper-btn" slot="button-next"></div>
      </swiper>
    </div>
    <div class="part part4">
      <h1>{{ setting.part4.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part4.des }}
      </div>
    </div>
    <div class="part4-img">
      <img
        src="http://cdn.topartsintermational.com/ancient-img7.jpg"
        width="100%"
      />
    </div>
    <div class="part row">
      <div class="partLeft col-6">
        <img
          src="http://cdn.topartsintermational.com/ancient-img8.jpg"
          width="100%"
        />
      </div>
      <div class="partRight col-6">
        <p>{{ setting.part4.cont }}</p>
      </div>
    </div>
    <div class="part part5">
      <h1>{{ setting.part5.title }}</h1>
      <!-- <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part5.des }}
      </div> -->
      <div class="row">
        <div class="col-6 left">
          <p>{{ setting.part5.cont }}</p>
          <p>{{ setting.part5.cont2 }}</p>
        </div>
        <div class="col-6 right">
          <a href="https://www.youtube.com/watch?v=ulSQnOU5PNw" target="_blank">
            <img
              src="http://cdn.topartsintermational.com/ancient-img9.jpg"
              width="100%"
            />
          </a>
        </div>
      </div>
    </div>
    <Footer />
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://cdn.topartsintermational.com/ancient-fir-s.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <el-dialog
      :visible.sync="visible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://cdn.topartsintermational.com/ancient-1.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from "../../components/footer"
export default {
  name: "Ancient",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      setting: {
        part1: {
          title: this.$t('message.ancient.part1.title'),
          des: this.$t('message.ancient.part1.des'),
          cont1: this.$t('message.ancient.part1.cont1'),
          cont2: this.$t('message.ancient.part1.cont2'),
        },
        part2: {
          title: this.$t('message.ancient.part2.title'),
          des: this.$t('message.ancient.part2.des'),
          cont: this.$t('message.ancient.part2.cont'),
        },
        part3: {
          title: this.$t('message.ancient.part3.title'),
          des: this.$t('message.ancient.part3.des'),
        },
        part4: {
          title: this.$t('message.ancient.part4.title'),
          des: this.$t('message.ancient.part4.des'),
          cont: this.$t('message.ancient.part4.cont'),
        },
        part5: {
          title: this.$t('message.ancient.part5.title'),
          des: this.$t('message.ancient.part5.des'),
          cont: this.$t('message.ancient.part5.cont'),
          cont2: this.$t('message.ancient.part5.cont2'),
        },
      },
      dialogVisible: true,
      visible: false,
      swiperOption: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
};
</script>

<style scoped lang="less">
p {
  margin: 0;
  padding: 0;
}
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
  background: url("http://cdn.topartsintermational.com/ancient-bj.jpg")
    no-repeat;
  background-size: cover;
}
.part {
  width: 1200px;
  text-align: center;
  color: #fff;
  padding: 16px 0;
  margin: 50px auto 0;
  line-height: 24px;
}
.part h1 {
  margin: 0;
  padding: 0;
  line-height: 36px;
}
.cont {
  width: 100%;
  display: flex;
}
.cont-left {
  width: 600px;
  overflow: hidden;
}
.cont-right {
  width: 600px;
  background: url("http://cdn.topartsintermational.com/warriors-img2.jpg")
    no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  text-indent: 2em;
  padding: 80px 80px 0;
  text-align: left;
  box-sizing: border-box;
}
.cont1-left {
  width: 600px;
  background: url("http://cdn.topartsintermational.com/ancient-img3.jpg")
    no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 18px;
  line-height: 1.8;
  text-indent: 2em;
  padding: 80px 80px 0;
  text-align: left;
  box-sizing: border-box;
}
.cont1-right {
  width: 600px;
  overflow: hidden;
}
.cont img {
  transition: all 0.6s;
}
.cont img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
.row {
  justify-content: space-between;
  .col {
    width: 48%;
  }
  .left {
    width: 665px;
    background: url("http://cdn.topartsintermational.com/ancient-img3.jpg")
      no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-indent: 2em;
    padding: 45px 50px 0;
    text-align: left;
    box-sizing: border-box;
  }
  .right {
    width: 515px;
    overflow: hidden;
    img {
      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.2);
      transition: all 0.6s;
    }
  }
}

.swiper {
  width: 100%;
  height: 300px;
  margin-top: 24px;
  &:hover {
    .swiper-btn {
      display: block;
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-btn {
    display: none;
  }
}
.part4-img {
  width: 100%;
  margin-top: 24px;
  overflow: hidden;
  img {
    transition: all 0.6s;
  }
  img:hover {
    transform: scale(1.2);
  }
}
.partLeft {
  overflow: hidden;
  img {
    transition: all 0.6s;
  }
  img:hover {
    transform: scale(1.2);
    transition: all 0.6s;
  }
}
.partRight {
  background: url("http://cdn.topartsintermational.com/ancient-img10.jpg")
    repeat;
  font-size: 14px;
  text-indent: 2em;
  line-height: 40px;
  color: #efeaea;
  padding: 50px 30px 0;
  box-sizing: border-box;
  text-align: left;
}
.part5 .row {
  margin: 24px 0;
  .left {
    background: url("http://cdn.topartsintermational.com/warriors-img2.jpg")
      no-repeat;
    background-size: cover;
    font-size: 18px;
    text-indent: 2em;
    line-height: 50px;
    color: #efeaea;
    padding: 150px 40px 0;
  }
  .right {
    overflow: hidden;
    img {
      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.2);
      transition: all 0.6s;
    }
  }
}
</style>